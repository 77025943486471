import React from "react";
import styles from "./index.module.scss";
import { GrCapacity, GrColumns } from "react-icons/gr";
import ColumnData from "./ColumnData";
import { Link } from "react-router-dom";
import { Container3D, MatrixContainer } from "../../shared/types/container";

interface ContainerInfoTableProps {
  container?: Container3D;
  gridName: string
}

function ContainerInfoTable({ container, gridName }: ContainerInfoTableProps) {

  return (
    <div className={styles["info-table"]}>
      {container ? (
        <>
          <ColumnData variable="Grid" value={gridName} />
          <ColumnData variable="Floor" value={container.z_axis} />
          <ColumnData variable="Number" value={<Link to={`/admin/container/${container.id}`}>{container.number}</Link>} />
          <ColumnData variable="Status" value={container.inside_name} />
          <ColumnData variable="Type" value={container.type_name} />
          {container.forwarder && (
            <ColumnData
              variable="Forwarder"
              value={
                <Link to={`/admin/company/${container.forwarder.id}`}>
                  {container.forwarder.name}
                </Link>
              }
            />
          )}
          {container.shipper && (
            <ColumnData
              variable="Shipper"
              value={
                <Link to={`/admin/company/${container.shipper.id}`}>
                  {container.shipper.name}
                </Link>
              }
            />
          )}
          {container.consignee && (
            <ColumnData
              variable="Consignee"
              value={
                <Link to={`/admin/company/${container.consignee.id}`}>
                  {container.consignee.name}
                </Link>
              }
            />
          )}
          <ColumnData
            variable="Invoice"
            value={container.invoice_number}
          />
          <ColumnData variable="B1" value={container.bl_number} />
          <ColumnData variable="Lot order" value={container.lot_order} />
          <ColumnData variable="Target" value={container.target_name} />
        </>
      ) :
        <div>
          <h3>This location is free</h3>
        </div>
      }
    </div>
  );
}

export default ContainerInfoTable;
