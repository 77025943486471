import React, { useState } from "react";
import { isError, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import jwtAxios from "../../../auth/jwt-api";
import { Button, DatePicker, Form, Input, Select, notification, Radio } from "antd";
import getOptionsfrom from "../../../utils/getOptionsFrom";
import styles from "../index.module.scss";
import { useForm } from "antd/es/form/Form";
import { CompanyType } from "../../../shared/types/CompanyType";
import dayjs from "dayjs";
import { urlBuilder } from "./helpers";
import FileUpload from "./components/FileUpload";

function Container() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState<"text" | "file">(id ? 'text' : 'file')
  const navigate = useNavigate();

  const [form] = useForm();
  const {
    data: container,
    isLoading,
    isError,
  } = useQuery(
    ["container", id],
    () => jwtAxios.get(`master/container/${id}`),
    {
      enabled: !!id,
      staleTime: 0,
      cacheTime: 0,
    }
  );

  const forwarderUrl = urlBuilder(`/master/company`, { type: 10 })
  const { data: forwarders } = useQuery(forwarderUrl, () => jwtAxios.get(forwarderUrl))

  const shipperUrl = urlBuilder(`/master/company`, { type: 20 })
  const { data: shippers } = useQuery(shipperUrl, () => jwtAxios.get(shipperUrl))

  const consigneUrl = urlBuilder(`/master/company`, { type: 30 })
  const { data: consignes } = useQuery(consigneUrl, () => jwtAxios.get(consigneUrl))

  const { data: statuses } = useQuery(["list-container-inside-types"], () =>
    jwtAxios.get(`master/list-container-inside-types`)
  );

  const { data: types } = useQuery(["list-container-types"], () =>
    jwtAxios.get(`master/list-container-types`)
  );

  const { data: targets } = useQuery(["list-container-targets"], () =>
    jwtAxios.get(`enum/list-container-targets`)
  );

  const handleSubmit = async (data: any) => {
    if (data.ata) {
      data.ata = data.ata.format("YYYY-MM-DD");
    }
    data.eta = data.eta.format("YYYY-MM-DD");
    data.shipping_date = data.shipping_date.format("YYYY-MM-DD");

    if (id && !form.isFieldsTouched()) {
      return notification.error({ message: "You didnt enter jack's shit" });
    }
    setLoading(true);
    try {
      await jwtAxios[id ? "put" : "post"](`master/container/${id || ""}`, data);
      notification.success({
        message: `Successfully ${id ? "changed" : "posted"}`,
      });
      navigate("/admin/table?page=container");
    } catch (error) {
      console.log({ error });
      notification.error({
        message: `Cannot ${id ? "change" : "post"} container`,
      });
    }
    setLoading(false);
  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    jwtAxios.get(`master/container-by-number/${event.target.value ?? 'no'}`)
      .then(res => {
        if (res.data.data) {
          if (res.data.error) {
            form.setFieldValue('number', '')
            notification.error({ message: res.data.error })
          } else {
            form.setFieldValue('type', res.data?.data?.type)
            form.setFieldValue('inside', res.data?.data?.inside)
            form.setFieldValue('forwarder_id', res.data?.data?.forwarder_id)
            form.setFieldValue('consignee_id', res.data?.data?.consignee_id)
            form.setFieldValue('shipper_id', res.data?.data?.shipper_id)
          }
        }
      })
      .catch(error => { })
  }


  if (isLoading) {
    return <>Loading...</>;
  }
  if (isError) {
    navigate("/admin/table?page=container");
    notification.error({ message: "Error occured!" });
    return <></>;
  }
  return (
    <div>
      <h1 style={{ paddingBottom: 30 }}>Container</h1>
      <div style={{ padding: '0px 0px 30px 0px' }}>
        <Radio.Group onChange={(event) => setInput(event.target.value)} value={input}>
          <Radio value="text">Forma</Radio>
          <Radio value="file">Fayl</Radio>
        </Radio.Group>
      </div>
      {(!id && input == 'file')
        ?
        <div>
          <FileUpload />
        </div>
        :
        <Form
          form={form}
          initialValues={
            container?.data
              ? {
                ...container?.data,
                shipper_id: container?.data.shipper?.id,
                forwarder_id: container?.data.forwarder?.id,
                consignee_id: container?.data.consignee?.id,
                shipping_date:
                  container?.data?.shipping_date &&
                  dayjs(container?.data.shipping_date, "YYYY-MM-DD"),
                ata:
                  container?.data?.ata &&
                  dayjs(container?.data.ata, "YYYY-MM-DD"),
                eta:
                  container?.data?.eta &&
                  dayjs(container?.data.eta, "YYYY-MM-DD"),
              }
              : {}
          }
          onFinish={handleSubmit}
          layout="vertical"
        >
          <div
            className={styles["form-grid"]}
            style={{ gridTemplateColumns: "1fr 1fr 1fr" }}
          >
            {/* <div>{JSON.stringify(container?.data)}</div> */}
            <Form.Item
              name={"number"}
              label="Container number"
              rules={[{ required: true, message: "Please  input this field!" }]}
            >
              <Input style={{ width: "100%" }} onChange={handleNumberChange} />
            </Form.Item>
            <Form.Item
              name={"type"}
              label="Type"
              rules={[{ required: true, message: "Please  input this field!" }]}
            >
              <Select
                style={{ width: "100%" }}
                options={getOptionsfrom(types?.data)}
              />
            </Form.Item>{" "}
            {id && <Form.Item
              name={"inside"}
              label="Yuk holati"
              rules={[{ required: true, message: "Please  input this field!" }]}
            >
              <Select
                style={{ width: "100%" }}
                options={getOptionsfrom(statuses?.data)}
              />
            </Form.Item>}
            <Form.Item
              name={"forwarder_id"}
              label="Forwarder"
              rules={[{ required: true, message: "Please  input this field!" }]}
            >
              <Select
                style={{ width: "100%" }}
                options={getOptionsfrom(forwarders?.data.data, "id", "name")}
              />
            </Form.Item>{" "}
            <Form.Item
              name={"shipper_id"}
              label="Shipper"
              rules={[{ required: true, message: "Please  input this field!" }]}
            >
              <Select
                style={{ width: "100%" }}
                options={getOptionsfrom(shippers?.data.data, "id", "name")}
              />
            </Form.Item>{" "}
            <Form.Item
              name={"consignee_id"}
              label="Consignee"
              rules={[{ required: true, message: "Please  input this field!" }]}
            >
              <Select
                style={{ width: "100%" }}
                options={getOptionsfrom(consignes?.data.data, "id", "name")}
              />
            </Form.Item>
            <Form.Item
              name={"shipping_date"}
              label="Shipping date"
              rules={[{ required: true, message: "Please  input this field!" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>{" "}
            {id && <Form.Item
              name={"ata"}
              label="ata"
              rules={[{ required: false }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            }
            <Form.Item
              name={"eta"}
              label="eta"
              rules={[{ required: true, message: "Please  input this field!" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>{" "}
            <Form.Item
              name={"invoice_number"}
              label="Invoice number"
              rules={[{ required: true, message: "Please  input this field!" }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>{" "}
            <Form.Item
              name={"bl_number"}
              label="bl Number"
              rules={[{ required: true, message: "Please  input this field!" }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name={"lot_order"}
              label="Lot order"
              rules={[{ required: false }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name={"target"}
              label="Target"
              rules={[{ required: false }]}
            >
              <Select
                style={{ width: "100%" }}
                options={getOptionsfrom(targets?.data)}
              />
            </Form.Item>{" "}
            <Form.Item
              name={"remark"}
              label="Remark"
              rules={[{ required: false }]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name={"coming_transport"}
              label="Olib kelgan transport"
              rules={[{ required: false }]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name={"returning_transport"}
              label="Olib ketgan transport"
              rules={[{ required: false }]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </div>
            <Button
              loading={loading}
              htmlType="submit"
              style={{ marginTop: 120 }}
              type="primary"
            >
              Submit
            </Button>
        </Form>
      }
    </div>
  );
}

export default Container;

const c = {
  number: "2521-container",
  type: 10,
  status: 10,
  invoice_number: "Kp98TlbBpZ",
  bl_number: "qHEl7QToh1",
  shipping_date: "2023-10-10",
  eta: "2023-10-10",
  ata: "2023-10-10",
  shipper_id: 1,
  forwarder_id: 1,
  consignee_id: 1,
};
