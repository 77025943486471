import styles from "./index.module.scss";
import { Button } from "antd";
import AddModal from "../Modals/AddModal";
import SendModal from "../Modals/SendModal";
import UnloadingModal from "../Modals/UnloadingModal";
import { Link } from "react-router-dom";
import SetbackModal from "../Modals/SetbackModal";
import { Popover } from "antd";
import { Grid3D, MatrixGrid } from "../../shared/types/container";
import { useQuery } from "react-query";
import jwtAxios from "../../auth/jwt-api";
import ContainerDownloadModal from "../../pages/admin/Container/components/ContainerDownloadModal";


export interface ContainerActionsProps {
  currentGrid: MatrixGrid | null;
  setCurrentGrid: (matrixGrid: MatrixGrid) => void;
  search: string
}


function ContainerActions({ currentGrid: currentGrid, setCurrentGrid, search }: ContainerActionsProps) {
  const { data: grid } = useQuery(
    ['grid', currentGrid?.name],
    () => jwtAxios.get<{ data: Grid3D }>(`/master/grid/${currentGrid?.name}`),
    {
      select: (res) => res?.data?.data,
      enabled: !!currentGrid?.name
    }
  );
  const isCurrentGridFull = grid ? grid.containers.length >= grid.area.limit : false
  const isCurrentGridNotEmpty = grid ? grid.containers.length > 0 : false
  return (
    <div className={styles.container}>
      <h1>Actions</h1>
      <div className={styles.buttons}>
        {!isCurrentGridFull && <AddModal currentGrid={currentGrid} search={search} setCurrentGrid={setCurrentGrid}>
          <Popover placement="left" title="Tizimga yangi qo'shilgan konteynerlarni maydonga joylashtirish Alt+A">
            <Button
              type="primary"
              style={{ background: "var(--color-red)" }}
              block disabled={isCurrentGridFull}
            >
              Add (Alt+A)
            </Button>
          </Popover>
        </AddModal>}
        {isCurrentGridNotEmpty &&
          <Popover placement="left" title="Maydondagi konteynerni maydondagi boshqa joyga ko'chirish">
            <Button
              type="primary"
              style={{ background: "var(--color-green)", color: "#2b2b2b" }}
              onClick={() => alert(`Konteynerni ko'chirish uchun ustiga sichqoncha kursorni olib boring va chap tugma bilan tutib kerakli joyga olib borib sichqonchadan qo'lingizni oling`)}
            >
              Move
            </Button>
          </Popover>
        }
        {currentGrid && isCurrentGridNotEmpty &&
          <UnloadingModal currentGrid={currentGrid} search={search} setCurrentGrid={setCurrentGrid}>
            <Popover placement="left" title="Maydondagi konteynerni warehousega jo'natish Alt+U">
                    <Button
                      type="primary"
                      // className={snapshot.isDraggingOver ? styles.draggingOver : ''}
                      style={{ background: "var(--color-orange)", color: "#2b2b2b" }}
                      block
                    >
                      Unload (Alt+U)
                    </Button>
              {/* <Droppable droppableId={makeDroppableId(DandActionsEnum.UNLOADING, DandPrefixEnum.CONTROL, 'UNLOADING')}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <span style={{ display: 'none' }}>{provided.placeholder}</span>
                  </div>
                )}
              </Droppable> */}
            </Popover>
          </UnloadingModal>
        }
        {currentGrid && !isCurrentGridFull &&
          <SetbackModal currentGrid={currentGrid} search={search} setCurrentGrid={setCurrentGrid}>
            <Popover placement="left" title="Warehousedagi konteynerni maydonga qaytarish Alt+S">
              <Button
                type="primary"
                style={{ background: "var(--color-orange)", color: "#2b2b2b" }}
                block disabled={isCurrentGridFull}
              >
                Setback (Alt+S)
              </Button>
            </Popover>
          </SetbackModal>
        }
        {currentGrid && isCurrentGridNotEmpty &&
          <SendModal currentGrid={currentGrid} search={search} setCurrentGrid={setCurrentGrid}>
            <Popover placement="left" title="Maydondagi konteynerni butunlay chiqarib yuborish Alt+R">
                    <Button
                      type="primary"
                      // className={snapshot.isDraggingOver ? styles.draggingOver : ''}
                      style={{ background: "var(--color-coral)", color: "#fff" }}
                      block
                    >
                      Return (Alt+R)
                    </Button>
              {/* <Droppable droppableId={makeDroppableId(DandActionsEnum.RETURNING, DandPrefixEnum.CONTROL, 'RETURNING')}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <span style={{ display: 'none' }}>{provided.placeholder}</span>
                  </div>
                )}
              </Droppable> */}
            </Popover>
          </SendModal>
        }
        <Popover title="Admin panel" placement="left">
          <Link to="/admin/table?page=container">
            <Button
              type="primary"
              style={{ background: "var(--color-yellow)", color: "#2b2b2b" }}
              block
            >
              Admin
            </Button>
          </Link>
        </Popover>

        <Popover title="Admin panel" placement="left">
        <ContainerDownloadModal text="Report" btntype="primary" />
        </Popover>
      </div>
    </div>
  );
}

export default ContainerActions;
