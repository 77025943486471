import { AreaNames, MatrixContainers } from "../../../shared/types/container";



export function filterMatrixContainers(data: MatrixContainers, search: string): MatrixContainers {

    return {
        A: searchByArea("A", data, search, 36, 10),
        B: searchByArea("B", data, search, 1, 2),
        C: searchByArea("C", data, search, 36, 10),
        D: searchByArea("D", data, search, 10, 3)
    }
}

function searchByArea(area: AreaNames, data: MatrixContainers, search: string, x_max: number, y_max: number) {
    let lines = []
    for (let y = 0; y < y_max; y++) {
        let line = []
        for (let x = 0; x < x_max; x++) {
            let containers = []
            for (let container of data[area][y][x].containers) {
                if (container.number.toLowerCase().includes(search.toLowerCase())) {
                    containers.push({ number: container.number, z_axis: container.z_axis })
                }
            }
            let grid = { ...data[area][y][x], containers }
            line.push(grid)
        }
        lines.push(line)
    }
    return lines
}