import { Popover } from "antd";
import { useState } from "react";
import { BiCheckbox, BiCheckboxChecked, BiSearchAlt } from "react-icons/bi";


function ExtraYard({ children }: { children: any }) {
    const [visible, setVisible] = useState(false);

    return <>

        <Popover title="Qo'shimcha maydonni ko'rish" placement="bottom">
            <button style={{ cursor: 'pointer' }} onClick={() => setVisible(!visible)}>
                {visible ?
                    <div>
                        <BiCheckboxChecked size={20} />
                    </div>

                    :
                    <BiCheckbox size={20} />
                }
            </button>
        </Popover>
        <div style={{ marginTop: 15 }}>
            {
                visible && children
            }
        </div>
    </>
}

export default ExtraYard;