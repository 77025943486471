import ContainerDownloadModal from "../Container/components/ContainerDownloadModal"

interface Modals {
    [key: string]: JSX.Element
}
interface DownloadModals extends Modals {
    container: JSX.Element
}

export const downloadModals: DownloadModals = {
    container: <ContainerDownloadModal />
}