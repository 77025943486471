import Train from "../../../components/sections/Train";
import ActionBar from "../../../components/sections/ActionBar";
import Yard from "../../../components/sections/Yard";
import { notification, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import jwtAxios from "../../../auth/jwt-api";
import { AreaNames, Container3D, MatrixContainers, MatrixGrid } from "../../../shared/types/container";
import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd";
import { useQuery, useQueryClient } from "react-query";
import { DandActionsEnum, extractDandId } from "../../../helpers/draganddrop";
import AddModal from "../../../components/KeyboardModals/AddModal";
import SetbackModal from "../../../components/KeyboardModals/SetbackModal";
import SendModal from "../../../components/KeyboardModals/SendModal";
import UnloadingModal from "../../../components/KeyboardModals/UnloadingModal";
import { filterMatrixContainers } from "./util";
import ExtraYard from "./ExtraYard";



function MainPage() {
  const [matrixContainers, setMatrixContainers] = useState<MatrixContainers | null>(null)
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [setbackModalOpen, setSetbackModalOpen] = useState(false)
  const [returnModalOpen, setReturnModalOpen] = useState(false)
  const [unloadModalOpen, setUnloadModalOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [dandAction, setDandAction] = useState(DandActionsEnum.RELOCATING)
  const [currentGrid, setCurrentGrid] = useState<MatrixGrid | null>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [filteredYard, setFilteredYard] = useState(false)


  const queryClient = useQueryClient()

  const { data: response, isLoading, refetch } = useQuery('container-matrix', () => {
    return jwtAxios.get<MatrixContainers>(`/master/container-matrix`)
  }, {
    onSuccess(res) {
      setMatrixContainers(JSON.parse(JSON.stringify(res.data)))
    },
  })

  const setCurrentGridCallback = useCallback((matrixGrid: MatrixGrid) => {
    setCurrentGrid(matrixGrid)
  }, [])

  const setCurrentGridByContainer = (container: Container3D) => {
    if (container.position) {
      const gridName = container.position.grid_name
      const [areaName, x, y] = gridName.split('.') as [AreaNames, number, number]
      const grid = response?.data[areaName][+y - 1][+x - 1] ?? null;
      setCurrentGrid(grid)
      queryClient.refetchQueries(['grid', gridName])
    }
  }

  const handleKeyDown = (event: any) => {
    const X_MAX = 36
    const Y_MAX = 10
    console.log(event)
    function setGrid(a: AreaNames, x: number, y: number) {
      if (a == 'B') return
      const grid = response?.data[a][y - 1][x - 1] ?? null;
      queryClient.refetchQueries(['grid', grid?.name])
      setCurrentGrid(grid)
    }

    function getAreaName(areaName: AreaNames): AreaNames {
      if (areaName == "A") return "C"
      else if (areaName == "C") return "A"
      else return "A"
    }

    if (currentGrid) {
      const [areaName, x, y] = currentGrid.name.split('.') as [AreaNames, string, string]
      let x_axis = +x
      let y_axis = +y
      let area = areaName
      if ((event.key == 'a' || event.key == 'A') && event.altKey) {
        setAddModalOpen(!addModalOpen)
      } else if ((event.key == 's' || event.key == 'S') && event.altKey) {
        setSetbackModalOpen(!setbackModalOpen)
      } else if ((event.key == 'r' || event.key == 'R') && event.altKey) {
        currentGrid && currentGrid.containers.length > 0 && setReturnModalOpen(!returnModalOpen)
      } else if ((event.key == 'u' || event.key == 'U') && event.altKey) {
        currentGrid && currentGrid.containers.length > 0 && setUnloadModalOpen(!unloadModalOpen)
      } else if (event.key == 'ArrowUp' && search.length == 0) {
        if (y_axis > 1) {
          y_axis -= 1
        } else {
          y_axis = Y_MAX
          area = getAreaName(area)
        }
        event.preventDefault()
        setGrid(area, x_axis, y_axis)
      } else if (event.key == 'ArrowDown' && search.length == 0) {
        if (y_axis < Y_MAX) {
          y_axis += 1
        } else {
          y_axis = 1
          area = getAreaName(area)
        }
        event.preventDefault()
        setGrid(area, x_axis, y_axis)
      } else if (event.key == 'ArrowLeft' && search.length == 0) {
        if (x_axis > 1) {
          x_axis -= 1
        } else {
          x_axis = X_MAX
        }
        event.preventDefault()
        setGrid(area, x_axis, y_axis)
      } else if (event.key == 'ArrowRight' && search.length == 0) {
        if (x_axis < X_MAX) {
          x_axis += 1
        } else {
          x_axis = 1
        }
        event.preventDefault()
        setGrid(area, x_axis, y_axis)
      }
    } else {
      // event.preventDefault()
      // setGrid("A", 1, 1)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [currentGrid, response?.data, addModalOpen, setbackModalOpen, unloadModalOpen, returnModalOpen, search]);




  return (
    <>
      {/* <div>{matrixContainers && JSON.stringify(matrixContainers.A[8][30].containers)}</div> */}
      <Spin spinning={isLoading}>
        {response && (<div>
          <Yard key={1}
            states={{
              section: (filteredYard && search && matrixContainers) ? filterMatrixContainers(matrixContainers, search).A : response.data.A,
              name: "A", dandAction, currentGrid, isDragging, search, filteredYard
            }}
            actions={{ setCurrentGrid: setCurrentGridCallback, setIsDragging }} />
          <Train
            states={{
              section: (filteredYard && search && matrixContainers) ? filterMatrixContainers(matrixContainers, search).B : response.data.B,
              name: "B", dandAction, currentGrid, isDragging, search, filteredYard
            }}
            actions={{ setCurrentGrid: setCurrentGridCallback, setIsDragging }} />
          <Yard key={2}
            states={{
              section: (filteredYard && search && matrixContainers) ? filterMatrixContainers(matrixContainers, search).C : response.data.C,
              name: "C", dandAction, currentGrid, isDragging, search, filteredYard
            }}
            actions={{ setCurrentGrid: setCurrentGridCallback, setIsDragging }} />

            <ExtraYard>
              <Yard key={2}
              states={{
                section: (filteredYard && search && matrixContainers) ? filterMatrixContainers(matrixContainers, search).D : response.data.D,
                name: "D", dandAction, currentGrid, isDragging, search, filteredYard
              }}
              actions={{ setCurrentGrid: setCurrentGridCallback, setIsDragging }} />
            </ExtraYard>
            
        </div>)}
      </Spin>
      <ActionBar
        states={{ currentGrid, dandAction, search, filteredYard }}
        actions={{ setSearch, setCurrentGridByContainer, setCurrentGrid: setCurrentGridCallback, setFilteredYard }} />
      {
        currentGrid &&
        <>
          <AddModal states={{ currentGrid, search, addModalOpen }} actions={{ setAddModalOpen, setCurrentGrid }} />
          <SetbackModal states={{ currentGrid, search, setbackModalOpen }} actions={{ setSetbackModalOpen, setCurrentGrid }} />
          <SendModal states={{ currentGrid, search, returnModalOpen }} actions={{ setReturnModalOpen, setCurrentGrid }} />
          <UnloadingModal states={{ currentGrid, search, unloadModalOpen }} actions={{ setUnloadModalOpen, setCurrentGrid }} />
        </>
      }
    </>
  );
}

export default MainPage;
