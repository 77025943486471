import { DatePicker, Table, TableProps } from "antd"
import { useQuery } from "react-query"
import jwtAxios from "../../auth/jwt-api"
import { useRef, useState } from "react";
import { getUrl } from "../../utils/getApi";
import { getDateFilter } from "../../utils/filters";
import moment from "moment";
import { BiSearchAlt } from "react-icons/bi";



function ContainerHistory() {
    const [filters, setFilters] = useState<any>({ sort: '-id' });
    const [search, setSearch] = useState('');
    const timeRef = useRef<NodeJS.Timeout>();

    const [tableParams, setTableParams] = useState({
        createdAt: '',
        pagination: {
            current: 1,
            pageSize: 5,
            total: 0
        },
    });

    const api = getUrl(
        "master/action",
        {
            ...filters,
            created_at: tableParams.createdAt,
            page: tableParams.pagination.current,
            rows: tableParams.pagination.pageSize,
        },
        search
    );

    const { data: containerActions, isLoading: actionsLoading } = useQuery(['container-actions', search, api], () => {
        return jwtAxios.get(api)
    }, {
        onSuccess(response) {
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data?.meta?.total,
                },
            });
            response.data?.data.forEach((el: any, index: number) => {
                el.no =
                    tableParams.pagination.current * tableParams.pagination.pageSize -
                    tableParams.pagination.pageSize +
                    index +
                    1;
                el.key = el.id;
            });
            if (response.data?.data.length == 0) {

            }
        },
    })

    const onChange: TableProps<any>["onChange"] = (
        pagination,
        changedeFilters,
        sorter: any,
        extra
    ) => {
        // console.log("changedeFilters", sorter, changedeFilters);
        let sort = sorter.columnKey;

        switch (sorter.order) {
            case "ascend":
                sort = sort;

                break;
            case "descend":
                sort = `-${sort}`;
                break;

            default:
                sort = "-id";
                break;
        }

        setFilters({ ...filters, ...changedeFilters, sort });
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        clearTimeout(timeRef.current);
        timeRef.current = setTimeout(() => {
            setSearch(e.target.value);
        }, 500);
    };
    return (<>
        <form action="" onClick={(e) => e.preventDefault()}>
            <input
                type="text"
                placeholder="Search..."
                onChange={handleSearch}
            />
            <DatePicker.RangePicker onChange={(dateValue) => {
                if (dateValue && dateValue[0] && dateValue[1]) {
                    const date1 = dateValue[0].format("YYYY-MM-DD");
                    const date2 = dateValue[1].format("YYYY-MM-DD");
                    setTableParams((state) => ({ ...state, createdAt: date1 === date2 ? date1 : `${date1}|${date2}` }))
                } else {
                    setTableParams((state) => ({ ...state, createdAt: '' }))
                }
            }} />
            <button>
                <BiSearchAlt />
            </button>
        </form>
        <Table
            style={{ marginTop: 30 }}
            size="small"
            scroll={{ x: 1100 }}
            dataSource={containerActions?.data.data || []}
            pagination={{
                ...tableParams.pagination,
                pageSize: tableParams.pagination.pageSize,
                onChange: (page, pageSize) => {
                    setTableParams({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            current: page,
                            pageSize: pageSize,
                        },
                    });
                },
            }}
            onChange={onChange}
            columns={[
                {
                    width: 70,
                    align: 'center',
                    title: "Raqami",
                    dataIndex: "container_number",
                    key: "container_number",
                    render(data) {
                        return <div style={{ padding: '10px' }}>{data}</div>
                    }
                },
                {
                    title: "Yuk holati",
                    dataIndex: "container_inside",
                    key: "container_inside",
                },
                {
                    title: "Faoliyat",
                    dataIndex: "action",
                    key: "action",
                    align: 'center'
                },
                {
                    title: "Hozirgi joy",
                    dataIndex: "position",
                    key: "position",
                },
                {
                    title: "Eski joy",
                    dataIndex: "old_position",
                    key: "old_position",
                },
                {
                    title: "Holati",
                    dataIndex: "container_status",
                    key: "container_status",
                },
                {
                    title: "Turi",
                    dataIndex: "container_type",
                    key: "container_type",
                },
                {
                    title: "Admin",
                    align: 'center',
                    dataIndex: "user_name",
                    key: "user_name",
                },
                {
                    title: "Forwarder",
                    dataIndex: "forwarder_name",
                    key: "forwarder_name",
                },
                {
                    title: "Shipper",
                    dataIndex: "shipper_name",
                    key: "shipper_name",
                },
                {
                    title: "Consigne",
                    dataIndex: "consignee_name",
                    key: "consignee_name",
                },
                {
                    title: "Olib kelgan transport",
                    dataIndex: "coming_transport",
                    key: "coming_transport",
                },
                {
                    title: "Olib ketgan transport",
                    dataIndex: "returning_transport",
                    key: "returning_transport",
                },
                {
                    title: "Sana va vaqt",
                    dataIndex: "created_at",
                    key: "created_at",
                    align: 'center',
                    sorter: true,
                    ...getDateFilter(),
                    render: (value: string) => <div><span style={{ fontWeight: 700 }}>{moment(value).format('HH:mm')}</span> {moment(value).format('MMM DD YYYY')}</div>
                },
            ]}
        ></Table>
    </>)
}

export default ContainerHistory