import React, { useState } from 'react'
import { MainPropType } from '../../shared/types'
import { Form, Input, Modal, notification, Spin } from "antd";

import styles from "./index.module.scss";
import { useContainerActions, useContainerState } from '../../providers/ContainersProvider/ContainersProvider';
import { useQuery, useQueryClient } from 'react-query';
import { Grid3D, MatrixGrid } from '../../shared/types/container';
import jwtAxios from '../../auth/jwt-api';

interface SendModalProps {
  children?: React.ReactNode;
  currentGrid: MatrixGrid;
  search: string;
  setCurrentGrid: (matrixGrid: MatrixGrid) => void;
}


function SendModal({ currentGrid, children, search, setCurrentGrid }: SendModalProps) {
  const queryClient = useQueryClient()
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  
  const [rt, setRt] = useState('');

  const container = currentGrid.containers[0];
  const { data: grid } = useQuery(
    ['grid', currentGrid?.name],
    () => jwtAxios.get<{ data: Grid3D }>(`/master/grid/${currentGrid?.name}`),
    {
      select: (res) => res?.data?.data,
      enabled: !!currentGrid?.name,
      onSuccess({ containers }) {
        setRt(containers[containers.length - 1]?.returning_transport ?? '')
      }
    }
  );

  const handleOk = async () => {
    if (!rt) return;
    if (loading) return;
    setLoading(true);
    jwtAxios.post<MatrixGrid>(`/master/container-returning`, {
      returning_transport: rt,
      grid_name: currentGrid.name,
      container_number: container.number
    })
      .then(res => {
        notification.success({ message: "Successfully moved out!" });
        queryClient.refetchQueries('container-matrix')
        queryClient.refetchQueries(['grid', currentGrid?.name])
        queryClient.refetchQueries('containers-for-positioning')
        queryClient.refetchQueries(['container-actions', search])
        setOpen(false);
        setLoading(false);
        setCurrentGrid(res.data)
      })
      .catch(err => {
        notification.error({ message: err.response.data.message });

      })
  }

  if (currentGrid?.containers.length == 0) {
    return <></>
  }
  return (
    <>
      <div onClick={() => setOpen(true)}>{children}</div>
      <Modal open={open} onOk={handleOk} onCancel={() => setOpen(false)}>
        <div style={{ marginTop: 30 }}>
          <label htmlFor="">Olib ketadigan transport nomi</label>
          <Input placeholder=''  value={rt} onChange={(ev) => setRt(ev.target.value)} />
        </div>
        <Spin spinning={loading}>
          <p className={styles["move-out-content"]}>
            Are you sure you wanna move out "
            {container?.number}"
            container?
          </p>
        </Spin>
      </Modal>
    </>
  );
}

export default SendModal